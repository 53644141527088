function ContactRightComponent() {
    // Function to navigate to the booking page
    const navigateToBooking = () => {
        window.location.href = '/booking'; // Replace with your booking page URL
    };

    // Function to initiate a call
    const initiateCall = () => {
        window.location.href = 'tel:02102855118'; // Replace with your phone number
    };

    return (
        <div className="contact-right-section">
            <h1>Contact Us</h1>
            <p>Contact Marley’s Mowing today – We’d love to hear from you.</p>
            
            <div className="contact-icons">
                <div className="phone-icon" onClick={initiateCall}>
                    <i className="icon-class-name"></i> {/* Use appropriate icon class or component */}
                    <p>PLEASE CALL FOR A FREE QUOTE</p>
                </div>
                
                <div className="form-icon" onClick={navigateToBooking}>
                    <i className="icon-class-name"></i> {/* Use appropriate icon class or component */}
                    <p>FOR AN ONLINE QUOTE CLICK HERE</p>
                </div>
            </div>
        </div>
    );
}

export default ContactRightComponent;