import React from 'react';
import '../Styles/PrideSection.css'; 

function PrideSection() {
    return (
        <div className="pride-section">
            <div className="pride-text">
                <h1>We Pride Ourselves On</h1>
                <h2>Low Costz and Quality Cutz</h2>
                <h3>Proudly NZ</h3>
            </div>
        </div>
    );
}

export default PrideSection;
