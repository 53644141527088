
import React from 'react';

function ContactTitle() {
    return(
        <div className="contact-intro">
            <h1>How To Contact Marley's Mowing</h1>
            <p>No job is too big or small.</p>
        </div>
    );
}

export default ContactTitle;