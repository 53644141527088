import React from 'react';
import Header from '../Components/Header'; // Path to the Header component
import Services from '../Components/HomePageServices'
import PrideSection from '../Components/HomePagePrideSection';
import WhyMarleysMowing from '../Components/WhyMarleysMowing';
import '../Styles/HomePage.css';
// ... Other imports

function HomePage() {
    return (
        <div className="homepage">
            <Header />
            <PrideSection />
            <Services />
            <WhyMarleysMowing />
            {/* Testitmonial */}
            {/* ... The rest of your page content */}
        </div>
    );
}

export default HomePage;
