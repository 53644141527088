import React, { useState } from 'react';
import BookingUserDetailsForm from './BookingUserDetailsForm';
import { LoadScript } from '@react-google-maps/api';
//import { getFunctions, httpsCallable } from 'firebase/functions';
//import app from '../firebase.js'; // Adjust the path as necessary


const LIBRARIES = ["places"];

const BookingForm = () => {
  const [formData, setFormData] = useState({
    selectedService: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    businessName: '',
    address: '',
    description: ''
  });

  const resetForm = () => {
    setFormData({
      selectedService: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      businessName: '',
      address: '',
      description: ''
      // reset other fields as needed
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

   // State to store the validation function
  const [validateUserDetailsForm] = useState(() => () => true);
   
  const handleUserDetailsChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (validateUserDetailsForm()) {
      // Construct the email subject
      let emailSubject = `New Booking Submission From: ${formData.firstName} ${formData.lastName}`;
      if (formData.businessName) {
        emailSubject += `, Business Name: ${formData.businessName}`;
      }
  
      // Construct the email data object
      const emailData = {
        subject: emailSubject,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        businessName: formData.businessName,
        description: formData.description,
        address: formData.address,
        // Include other booking details you want to send
      };
  
      try {
        // Make the POST request to the Firebase function endpoint
        const response = await fetch('https://us-central1-marleysmowing-fe3b7.cloudfunctions.net/api/sendEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData)
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.text();
        console.log("Email sent successfully:", result);
        resetForm();
        setSubmissionMessage('Email sent successfully!');
      } catch (error) {
        console.error("Error sending email:", error);
        setSubmissionMessage('Error sending email. Please try again.');
      }
      setIsSubmitting(false);
    } else {
      console.error("Validation failed");
      setIsSubmitting(false);
      setSubmissionMessage('Validation failed. Please check your input.');
    }
  };
  

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}
      libraries={LIBRARIES}
    >
      <BookingUserDetailsForm
        userDetails={formData}
        onUserDetailsChange={handleUserDetailsChange}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting} // pass this prop to disable the button
      />
      {submissionMessage && <div>{submissionMessage}</div>}
    </LoadScript>
  );
};

export default BookingForm;