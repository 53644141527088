import React from 'react';
import '../Styles/WhyMarleysMowing.css';

function WhyMarleysMowing() {
    return (
        <div className="marleys-section">
            <h2>Why Marley's Mowing?</h2>
            
            <div className="feature-grid">
                <div className="feature-card">
                    <span role="img" aria-label="leaf" className="emoji">🍃</span>
                    <h3>Personalized care for your garden</h3>
                </div>
                
                <div className="feature-card">
                    <span role="img" aria-label="location" className="emoji">📍</span>
                    <h3>Locally known and trusted</h3>
                </div>
                
                <div className="feature-card">
                    <span role="img" aria-label="handshake" className="emoji">🤝</span>
                    <h3>Always ready to communicate</h3>
                </div>
                
                <div className="feature-card">
                    <span role="img" aria-label="tools" className="emoji">🔧</span>
                    <h3>Dedication to quality service</h3>
                </div>
                
                <div className="feature-card">
                    <span role="img" aria-label="plant" className="emoji">🌿</span>
                    <h3>Passion for your green space</h3>
                </div>
            </div>
        </div>
    );
}

export default WhyMarleysMowing;
//Test