// Header.jsx
import React, { useState, useEffect } from 'react';
import logo from '../Assets/Logo.png';
import '../Styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Dropdown from './Dropdown';
import classnames from 'classnames'; // Make sure to install the classnames package
import { Link } from 'react-router-dom'; // Import Link if you're using React Router

// Services array (consider moving this to a separate file if it's shared across components)
const services = [
  { label: "Lawn Mowing", link: "/services" },
  { label: "Hedge Trimming", link: "/services" },
  { label: "Waste Removal", link: "/services" },
  { label: "Gardening", link: "/services" },
];

function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);

  // Close nav if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.header')) {
        setNavVisibility(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle nav visibility
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <div className="header">
      <Link to="/"> {/* Use Link for client-side routing */}
        <img src={logo} alt="Company Logo" className="logo" />
      </Link>
      <div 
        className={classnames('hamburger-menu', { 'active': isNavVisible })}
        onClick={toggleNav}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <nav 
        className={classnames('nav-links', { 'active': isNavVisible })}
      >
        <Dropdown label="Services" items={services} />
        <div className="nav-item"><a href="/aboutus" className="link">About Us</a></div>
        <div className="nav-item"><a href="/contact" className="link">Contact Us</a></div>
        <div className="nav-item"><a href="/booking" className="booking-btn">Book Now</a></div>
      </nav>
    </div>
  );
}

export default Header;
