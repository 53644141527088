import React from 'react';
import Header from '../Components/Header';
import AboutUsContent from '../Components/AboutUsContent';
import '../Styles/AboutPage.css'; // Your CSS file for styling

function AboutPage ()  {
  return (
    <div className="aboutpage">
      <Header />
      <AboutUsContent />
      {/* Other components or sections can be added here as needed */}
    </div>
  );
}

export default AboutPage;
