import React from 'react';
import '../Styles/Services.css'; // Assuming you have a separate CSS file for Services.

function Services() {
    return (
        <div className="services-section">
            <h2>Our Services</h2>
            <h3>From Katikati to Waihi: Cutz You Desire, Costz You Admire!</h3>
            <div className="service-card-section">
                <div className="service-card">
                    <img src="path_to_lawn_mowing_image.jpg" alt="Lawn Mowing"/>
                    <h3>Lawn Mowing</h3>
                </div>
                <div className="service-card">
                    <img src="path_to_hedge_trimming_image.jpg" alt="Hedge Trimming"/>
                    <h3>Hedge Trimming</h3>
                </div>
                <div className="service-card">
                    <img src="path_to_waste_removal_image.jpg" alt="Waste Removal"/>
                    <h3>Waste Removal</h3>
                </div>
                <div className="service-card">
                    <img src="path_to_gardening_image.jpg" alt="Gardening"/>
                    <h3>Gardening</h3>
                </div>
            </div> 
        </div>
    );
}

export default Services;
