import React, { useState } from 'react';
import BookingFormInput from './BookingFormInput';
import AutoCompleteAddress from './AutoCompleteAddress';
import '../Styles/BookingPage.css';

const BookingUserDetailsForm = ({ userDetails, onUserDetailsChange, onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for tracking submission
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    
    if (!userDetails.firstName.trim()) {
      newErrors.firstName = 'First name is required.';
    } else if (userDetails.firstName.trim().length < 2) {
      newErrors.firstName = 'First name must be at least 2 characters.';
    } else if (userDetails.firstName.trim().length > 100) {
      newErrors.firstName = 'First name must be less than 50 characters.';
    } else if (!/^[a-zA-Z-' ]+$/.test(userDetails.firstName.trim())) {
      newErrors.firstName = 'First name contains invalid characters.';
    }
    

    if (!userDetails.lastName.trim()) {
      newErrors.lastName = 'Last name is required.';
    } else if (userDetails.lastName.trim().length < 2) {
      newErrors.lastName = 'Last name must be at least 2 characters.';
    } else if (userDetails.lastName.trim().length > 100) {
      newErrors.lastName = 'Last name must be less than 50 characters.';
    } else if (!/^[a-zA-Z-' ]+$/.test(userDetails.lastName.trim())) {
      newErrors.lastName = 'Last name contains invalid characters.';
    }
    

    // Email validation
    if (!userDetails.email) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
      newErrors.email = 'Email address is invalid.';
    }

    if (!userDetails.phone.trim()) {
      newErrors.phone = 'Phone number is required.';
    } else if (!/^(\+64[\s]?[2-9]|0[2-9]|2[\s]?[0-9])[0-9]{6,8}$/.test(userDetails.phone.trim())) {
      newErrors.phone = 'Phone number is invalid.';
    } else if (userDetails.phone.trim().length > 20) {
      newErrors.phone = 'Phone number must be less than 12 characters.';
    }
    


    // Service validation
    if (!userDetails.selectedService || userDetails.selectedService === "") {
      newErrors.selectedService = 'Please select a service.';
    }

    // Business Name validation (if provided)
    if (userDetails.businessName) {
      if (userDetails.businessName.trim().length > 100) {
        newErrors.businessName = 'Business name must be less than 100 characters.';
      } else if (!/^[a-zA-Z0-9 \-']+$/.test(userDetails.businessName.trim())) {
        newErrors.businessName = 'Business name contains invalid characters.';
      }
    }


    // Address validation
    if (!userDetails.address.trim()) {
      newErrors.address = 'Address is required.';
    } else if (!userDetails.address.includes("New Zealand")) {
      newErrors.address = 'Address must be in New Zealand.';
    } else if (userDetails.address.trim().length > 200) {
      newErrors.address = 'Address must be less than 200 characters.';
    }
    

    // Description validation
    if (!userDetails.description.trim()) {
      newErrors.description = 'Description is required.';
    } else if (userDetails.description.trim().split(/\s+/).length < 5) {
      newErrors.description = 'Description must be at least 5 words.';
    } else if (userDetails.description.trim().length > 500) {
      newErrors.description = 'Description must be less than 500 characters.';
    }
    


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      await onSubmit(event);
      setIsSubmitting(false);
      setIsSubmitted(true); // Set isSubmitted to true after successful submission
    }
  };

  if (isSubmitted) {
    return (
      <div className="overlay">
        <div className="overlay-content">
          <p>Form successfully submitted, we will get back to you as soon as possible!</p>
          <button onClick={() => window.location.href = '/'}>Go to Homepage</button>
        </div>
      </div>
    );
  }
  

  return (
    <form onSubmit={handleFormSubmit} className="user-details-form">
      <div className="form-group">
        <BookingFormInput
          type="text"
          label="First Name"
          name="firstName"
          value={userDetails.firstName}
          onChange={onUserDetailsChange}
          required
          error={errors.firstName} // Pass the error for this field
        />
      </div>
      <div className="form-group">
        <BookingFormInput
          type="text"
          label="Last Name"
          name="lastName"
          value={userDetails.lastName}
          onChange={onUserDetailsChange}
          required
          error={errors.lastName} // Pass the error for this field
        />
      </div>
      <div className="form-group">
        <BookingFormInput
          type="email"
          label="Email"
          name="email"
          value={userDetails.email}
          onChange={onUserDetailsChange}
          required
          error={errors.email} // Pass the error for this field
        />
      </div>
      <div className="form-group">
        <BookingFormInput
          type="text"
          label="Phone"
          name="phone"
          value={userDetails.phone}
          onChange={onUserDetailsChange}
          required
          error={errors.phone} // Pass the error for this field
        />
      </div>
      <div className="form-group">
        <div className="form-group">
          <label htmlFor="selectedService">Select a Service:</label>
          <select
            name="selectedService"
            value={userDetails.selectedService}
            onChange={onUserDetailsChange}
            required
            error={errors.selectedService} // Pass the error for this field
            className="form-control" // Use the existing class that styles other form controls
          >
            <option value="">--Please choose an option--</option>
            <option value="mowing_regular">Mowing - Regular</option>
            <option value="mowing_once_off">Mowing - Once off</option>
            <option value="gardening_regular">Gardening - Regular</option>
            <option value="gardening_once_off">Gardening - Once off</option>
            <option value="hedge_trimming_regular">Hedge Trimming - Regular</option>
            <option value="hedge_trimming_once_off">Hedge Trimming - Once off</option>
            <option value="waste_removal">Waste Removal</option>
            {/* Add other options as needed */}
          </select>
        </div>
      </div>
      <div className="form-group">
        <BookingFormInput
          type="text"
          label="Business Name (Optional)"
          name="businessName"
          value={userDetails.businessName}
          onChange={onUserDetailsChange}
          error={errors.businessNameName} // Pass the error for this field
        />
      </div>
      <div className="form-group">
      <AutoCompleteAddress
        onPlaceSelected={(place) => onUserDetailsChange({ target: { name: 'address', value: place } })}
        onAddressChange={(newAddress) => onUserDetailsChange({ target: { name: 'address', value: newAddress } })}
        // Assuming you have other necessary props here as well
      />

      {/* Add this block to render the error message */}
      {errors.address && (
        <div>{errors.address}</div>
      )}
      </div>
      <div className="form-group">
        <BookingFormInput
          type="textarea"
          label="Give us a general idea"
          name="description"
          value={userDetails.description}
          onChange={onUserDetailsChange}
          required
          error={errors.description} // Pass the error for this field
        />
      </div>
      <button type="submit" className="submit-button" disabled={isSubmitting}>
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
      {Object.keys(errors).length > 0 && (
        <div className="form-errors">
          {Object.values(errors).map((error, index) => (
            <div key={index} className="error-message">{error}</div>
          ))}
        </div>
      )}
    </form>
  );
}

export default BookingUserDetailsForm;