import React from 'react';
import Header from '../Components/Header'; 
import ServicesPageInfo from '../Components/ServicesPageInfo';
import AboutMarleys from '../Components/ServicesAboutMarleys';

// ... Other imports

function ServicesPage() {
    return (
        <div className="servicespage">
            <Header />
            <AboutMarleys />
            <ServicesPageInfo />
            {/* Any other components specific to this page */}
        </div>
    );
}

export default ServicesPage;
