// BookingPage.js

import React from 'react';
import Header from '../Components/Header';
import BookingForm from '../Components/BookingForm'



function BookingPage() {
    return (
        <div className="bookingpage">
                <Header />
                <BookingForm />
        </div>
    );
}

export default BookingPage;
