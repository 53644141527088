import React from 'react';
import '../Styles/ServicesPageInfo.css'

function AboutMarleys() {
    return (
        <section className="about-marleys">
            <h2>Marley's Comprehensive Outdoor Services</h2>
            <h4>At Marley's Mowing, we're not just about cutting grass. 
                We offer a holistic approach to outdoor care, encompassing lawn mowing, expert gardening, precise hedge trimming, and efficient waste removal. 
                Every service is tailored to meet the unique needs of your outdoor space, ensuring it looks pristine and well-maintained.</h4>
        </section>
    );
}

export default AboutMarleys;
