import React from 'react';
import ContactLeftComponent from '../Components/ContactLeftComponent';
import ContactRightComponent from '../Components/ContactRightComponent';
import ContactTitle from '../Components/ContactPageTitle';
import '../Styles/ContactComponent.css';

function ContactComponent() {
    return (
        <div className="contact-component">
            <ContactTitle />
            <div className="contact-content-wrapper">
                <ContactLeftComponent />
                <ContactRightComponent />
            </div>
        </div>
    );
}

export default ContactComponent;
