import React from 'react';
import '../Styles/ServicesPageInfo.css';
import image1 from '../Assets/ServicesPage/MowedLawn1.jpg'
import image2 from '../Assets/ServicesPage/MowedLawn2.jpg'

function ServicesPageInfo() {
    return (
        <div className="services-info">

        {/* Grouped Text Section */}
        <div className="text-section">
            {/* Service Description */}
            <section className="service-description">
                <h2>Tailored Solutions for Every Outdoor Space</h2>
                <h4>
                Your outdoor space is unique, and it deserves a personalized touch. 
                With Marley's Mowing, benefit from a range of services – from mowing to gardening – all customized to fit your specific needs.
                </h4>

            </section>

            {/* Pricing */}
            <section className="pricing-section">
                <h1>Pricing</h1>
                <ul className="pricing-list">
                    <li><h3>Small Lawns start from $30</h3></li>
                    <li><h3>Medium Lawns start from $45</h3></li>
                    <li><h3>Large Lawns start from $60</h3></li>
                    <li><h3>Gardening starts from $40 p/h</h3></li>
                    <li><h3>Hedge Trimming starts from $150</h3></li>
                    <li><h3>Waste Removal starts from $100</h3></li>
                </ul>
                <div className="special-offer">
                    <span>Special Offer:</span> Sign up on a 4-month contract and get your first cut free!
                </div>
                <a href="/booking" className="booking-btn">Book Now</a>
            </section>
        </div>

        {/* Image Carousel */}
        <section className="image-carousel">
            <h2>Our Work</h2>
            <div className="carousel-container">
                {/* You'll eventually replace these placeholders with actual images */}
                <div className="carousel-item">
                    <img src={image1} alt="Lawn mowing example 1" />
                </div>
                <div className="carousel-item">
                    <img src={image2} alt="Lawn mowing example 2" />
                </div>
                {/* ... Add more images as needed */}
            </div>
        </section>

        </div>

    );
}

export default ServicesPageInfo;
