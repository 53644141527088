// AboutUsContent.jsx
import React from 'react';
import '../Styles/AboutPage.css';

function AboutUsContent() {
  return (
    <div className="about-us-content">
      <section className="our-journey">
        <h2>Our Journey</h2>
        <p>
          Established in 2023 by Stacey Ahipene, Marleys Mowing is your trusted partner in maintaining the beauty and health of your lawn and garden. Based in the lush regions between Waihi and Katikati, our mission is to deliver high-quality, affordable mowing, gardening, and property services to our community.
        </p>
      </section>

      <section className="our-services">
        <h2>Our Services</h2>
        <p>
          We offer a comprehensive range of services tailored to keep your outdoor spaces looking their best. From precision lawn mowing to meticulous garden care and property maintenance, we ensure every blade of grass and every leaf reflects our commitment to excellence.
        </p>
      </section>

      <section className="our-promise">
        <h2>Our Promise</h2>
        <p>
          We believe in fostering lush lawns and blooming gardens that serve as a testament to our meticulous workmanship. Marleys Mowing is more than a service provider; we are caretakers of your outdoor sanctuary. With every job, we pledge to uphold our standards of quality, ensuring your satisfaction with every sweep of the blade.
        </p>
      </section>

      <section className="community-commitment">
        <h2>Our Community Commitment</h2>
        <p>
          At Marleys Mowing, community comes first. We're proud to serve the families and businesses between Waihi and Katikati, and we're dedicated to contributing to our region's natural beauty. Our team is fully insured, expertly trained, and equipped with the best tools to deliver the service you deserve.
        </p>
      </section>

      <section className="join-our-journey">
        <h2>Join Our Green Journey</h2>
        <p>
          As we cultivate green lawns and lush landscapes, we invite you to join us on our green journey. Partner with Marleys Mowing for services that nurture your outdoor spaces and our planet.
        </p>
      </section>

      <div className="contact-us">
        <h2>Get in Touch</h2>
        <p>
          Ready to experience the Marleys Mowing difference? Contact us today for a consultation, and let's start crafting the perfect outdoor experience together.
        </p>
        <button onClick={() => window.location.href = '/contact'}>Contact Us</button>
      </div>
    </div>
  );
}

export default AboutUsContent;
