import React from 'react';
import '../Styles/BookingPage.css';

function BookingFormInput({ type, label, name, value, onChange, error }) {
  const inputElement = type === "textarea" ? (
    <textarea 
      id={name} 
      name={name} 
      value={value} 
      onChange={onChange}
      className={`form-control ${error ? 'is-invalid' : ''}`}
    ></textarea>
  ) : (
    <input 
      type={type} 
      id={name} 
      name={name} 
      value={value} 
      onChange={onChange}
      className={`form-control ${error ? 'is-invalid' : ''}`}
    />
  );

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      {inputElement}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export default BookingFormInput;
