import React, { useState, useRef, useEffect } from 'react';
import '../Styles/Dropdown.css';

function Dropdown({ label, items }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        // Event listener to close the dropdown if clicked outside
        function handleOutsideClick(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Add event listener to handle clicks outside the dropdown
        if (isOpen && window.innerWidth < 768) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            // Remove event listener on cleanup
            if (window.innerWidth < 768) {
                document.removeEventListener('mousedown', handleOutsideClick);
            }
        };
    }, [isOpen]);

    const toggleDropdown = (e) => {
        // Check if we are not in mobile view
        if (window.innerWidth >= 768) {
            // Do nothing if we are hovering on non-mobile devices
            if (e.type === 'click' && !isOpen) {
                setIsOpen(true);
            } else if (e.type === 'click' && isOpen) {
                setIsOpen(false);
            }
        } else {
            // This is for mobile view
            setIsOpen(!isOpen);
        }
    };

    // Separate function to handle hover for desktop view
    const handleMouseEnter = () => {
        if (window.innerWidth >= 768) {
            setIsOpen(true);
        }
    };

    const handleMouseLeave = () => {
        if (window.innerWidth >= 768) {
            setIsOpen(false);
        }
    };

    return (
        <div className="dropdown" ref={dropdownRef} onClick={toggleDropdown} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <button className="dropbtn">
                {label}
                <span className="arrow-down"></span>
            </button>
            {isOpen && (
                <div className="dropdown-content">
                    {items.map((item) => (
                        <a href={item.link} key={item.label}>
                            {item.label}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Dropdown;
