// AutoCompleteAddress.jsx
import React, { useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import '../Styles/BookingPage.css';

function AutoCompleteAddress({ onPlaceSelected, onAddressChange }) {
  const autocompleteRef = useRef(null);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      onPlaceSelected(place); // Call the onPlaceSelected if you need to use other place data
      onAddressChange(place.formatted_address); // Update the parent component's state with the address
    }
  };

  return (
    <div className="booking-address">
      <label>Address</label>
      <Autocomplete
        onLoad={(ref) => (autocompleteRef.current = ref)}
        onPlaceChanged={handlePlaceChanged}
        options={{ componentRestrictions: { country: 'nz' } }}
      >
        <input
          type="text"
          placeholder="Enter address"
          className="autocomplete-input" // Using className instead of inline styles
        />
      </Autocomplete>
    </div>
  );
}

export default AutoCompleteAddress;
