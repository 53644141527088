// ContactPage.js
import React from 'react';
import Header from '../Components/Header'; // Path to the Header component
import ContactComponent from '../Components/ContactComponent';
import WhyMarleysMowing from '../Components/WhyMarleysMowing';

function ContactPage() {
    return (
        <div className="contactpage">
            <Header />
            <ContactComponent />
            <WhyMarleysMowing />
        </div>
    );
}

export default ContactPage;