import React from 'react';
import './App.css';
import HomePage from './Pages/HomePage';
import ServicesPage from './Pages/ServicesPage';  // Import the Services page
import AboutPage from './Pages/AboutPage';
import ContactPage from './Pages/ContactPage';
import BookingPage from './Pages/BookingPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Notice the change from Switch to Routes

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
