// src/components/PrivacyPolicyPage.js

import React from 'react';
import Header from '../Components/Header'; 
import '../Styles/PrivacyPolicy.css';

function PrivacyPolicyPage() {
  return (
    <div className="privacypolicypage">
      <Header />
      <div className="content-container">
        <h1>Privacy Policy of Marley's Mowing</h1>

        <h2>Introduction</h2>
        <p>
          Marley's Mowing is committed to protecting the privacy of our clients. This privacy policy outlines our practices concerning the collection, use, and sharing of personal information.
        </p>

        <h2>Information Collection</h2>
        <p>
          We collect personal information necessary to fulfill our service commitments, including but not limited to your name, address, email address, phone number, and business details. This information is gathered through our booking form when a customer expresses interest in our lawn mowing services.
        </p>

        <h2>Use of Information</h2>
        <p>
          The collected information enables Marley's Mowing to schedule services and provide quotes efficiently. It also assists us in identifying the client for subsequent communication and service delivery.
        </p>

        <h2>Data Sharing with Mailjet</h2>
        <p>
          We utilize Mailjet, a third-party mailing service, to manage and dispatch booking information via email. Mailjet may have access to this data solely for the purpose of email transmission on behalf of Marley's Mowing. By submitting the booking form, customers grant us permission to process their information in this manner.
        </p>

        <h2>Data Security</h2>
        <p>
          We employ SSL encryption to safeguard the transmission of user data. Stored data is securely maintained in an email account accessible only to authorized personnel of Marley's Mowing.
        </p>

        <h2>User Rights</h2>
        <p>
          Customers have the right to inquire about the personal data we hold, request amendments, deletion of their information, or opt out of any communications from Marley's Mowing.
        </p>

        <h2>Changes to Our Privacy Policy</h2>
        <p>
          We reserve the right to modify this privacy policy. Should any changes occur, we will notify our customers through an announcement on our website and, where appropriate, via direct communication. We recommend that our customers review our privacy policy periodically to stay informed of any updates.
        </p>

        <h2>Contacting Us</h2>
        <p>
          For any inquiries or concerns regarding our privacy policy or if you wish to exercise any of your rights as described above, please contact us at <a href="mailto:marleysmowingservices@gmail.com">marleysmowingservices@gmail.com</a> or call us at 02102855118.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicyPage;
